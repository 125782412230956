.image-div-right.contact img {
    min-height: 300px;
    object-fit: cover;
}

.heading.contact {
    width: 100%;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    font-size: 40px;
    line-height: 45px;
    text-align: left;
}

.get-in-touch-form-wrap {
    margin-top: 50px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-bottom: 100px;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.contact-label {
    font-family: Franklin, sans-serif;
    color: #332d2c;
    font-size: 28px;
    font-weight: bold;
    text-transform: none;
}

.credentials-inputs-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.email-name-field-wrap {
    width: 50%;
    padding-left: 10px;
}

.contact-name-field-wrap {
    width: 50%;
    padding-right: 10px;
}

.text-field {
    width: 100%;
    margin-bottom: 18px;
    padding: 5px;
    border: 1px solid #e4e4e4;
    border-radius: 0px;
    -webkit-transition: border-color 500ms ease;
    transition: border-color 500ms ease;
    font-family: 'Neuzeitgro lig', sans-serif;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    border-radius: 2px;
    height: 50px;
    outline: 0;
}

.text-field.cc-textarea {
    height: 200px;
    padding-top: 12px;
}

.button {
    width: 100%;
    padding: 20px 25px;
    border-style: solid;
    border-width: 0px;
    border-color: #fbf9f7;
    border-radius: 0px;
    background-color: #332d2c;
    -webkit-transition: box-shadow 500ms ease;
    transition: box-shadow 500ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
}

.button:hover {
    background-color: #3d3a39
}

h1.heading.contact {
    white-space: pre-line;
}

.overlay {
    position: absolute;
    background: rgba(0, 0, 0, .5);
    z-index: 11;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    display: none;
}

.confirmation {
    width: 25%;
    min-width: 300px;
    min-height: 200px;
    background-color: #fff;
    border-radius: 2px;
    padding: 40px;
    border: 1px solid #e4e4e4;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.confirmation .headline {
    font-weight: bold;
    font-size: 30px;
    font-family: Franklin, sans-serif;
    margin-bottom: 10px;
}

.confirmation .text-block {
    font-size: 20px;
}