footer {
    margin-top: 150px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px 30px;
    border-style: none solid solid;
    border-width: 1px 0px 0px;
    border-color: #e8e6ee #000 #000;
    background-color: #fff;
}

footer .footer-grid {
    display: grid;
    padding-top: 80px;
    padding-bottom: 60px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 2fr 30px 1.25fr 30px 1.25fr 30px 1.25fr 30px 1.25fr;
    grid-template-columns: 2fr 1.25fr 1.25fr 1.25fr 1.25fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    line-height: 20px;
}

footer .footer-link {
    display: block;
    margin-bottom: 12px;
    opacity: 0.6;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    color: #43464d;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

footer .footer-link::first-letter {
    text-transform: uppercase;
}

footer .label-footer {
    margin-bottom: 20px;
    font-family: Franklin, sans-serif;
    color: #332d2c;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: none;
}

footer .copyright {
    display: block;
    width: 100%;
    color: #332d2c;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 5;
    grid-column-end: span 5;
}

footer .text-block-footer {
    margin-top: 20px;
    color: #353535;
    line-height: 28px;
}

footer .logo-footer {
    margin-top: -5px;
}

footer .cursor {
    pointer-events: none;
}

@media screen and (max-width: 767px) {
    footer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-top: 100px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        grid-auto-columns: 1fr;
        -ms-grid-columns: 2fr 1.25fr 1.25fr 1.25fr 1.25fr;
        grid-template-columns: 2fr 1.25fr 1.25fr 1.25fr 1.25fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        text-align: center;
    }
    .footer-grid {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex !important;
        padding-top: 100px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-grid-rows: auto;
        grid-template-rows: auto;
        text-align: center;
        padding-top: 0 !important;
    }
    .text-block-footer {
        padding-left: 0 !important;
    }
}