img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

h1.heading.atelier {
    font-size: 85px
}

.gallery-grid-container.top {
    position: relative;
    z-index: -1;
    margin-top: -35px;
    -ms-grid-columns: 1.5fr 0.5fr;
    grid-template-columns: 1.5fr 0.5fr;
}

.gallery-grid-container {
    display: -ms-grid;
    display: grid;
    margin-top: 60px;
    -ms-grid-row-align: center;
    align-self: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-areas: ".";
    -ms-grid-columns: 1.25fr 20px 0.75fr;
    grid-template-columns: 1.25fr 0.75fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.gallery-thumbnail.image,
.gallery-thumbnail.img {
    border: 60px solid #e0d6cb;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
}

.gallery-thumbnail {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

.atelier-text-right {
    align-self: center;
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
}

.heading.atelier.div {
    position: relative;
    margin-left: 0px;
    color: #e0d6cb;
    font-size: 120px;
    line-height: 100px;
    text-align: right;
    text-shadow: 0 0 1px transparent;
    padding-right: 20px;
}

.heading.atelier.div.div2 {
    text-align: left;
}

.text-atelier-1 {
    position: relative;
    margin-top: -15px;
    margin-left: 0px;
    padding: 0px;
    border: 0px solid #fff;
    line-height: 28px;
    text-align: left;
    font-size: 20px;
}

.headline-block-atelier {
    margin: 100px 0;
}

.headline-atelier {
    position: static;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: Franklin, sans-serif;
    color: #2d2c30;
    font-size: 85px;
    line-height: 92px;
    font-weight: bold;
    text-align: left;
    text-transform: lowercase;
}

.text-address {
    margin-bottom: 0px;
    font-family: 'Neuzeitgro lig', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    text-align: left;
    letter-spacing: 11px;
    text-transform: uppercase;
    color: #8d8d8d
}

.gallery-grid-container.secondary {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.div-block-atelier-2 {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    margin-top: -20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-block-atelier {
    position: relative;
    margin-top: -5px;
    margin-left: 0px;
    padding: 0px;
    border: 0px solid #fff;
    background-color: rgba(251, 249, 247, 0.53);
    line-height: 28px;
    text-align: left;
    font-size: 20px;
}

.atelier.image {
    position: relative;
    display: block;
    overflow: visible;
    width: 100%;
    height: 500px;
    border-style: solid;
    border: none;
    border-radius: 0px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

.gallery-thumbnail.image.bottom.center {
    border-width: 40px;
    border-color: #e0d6cb;
}

.gallery-thumbnail.image.bottom {
    border-width: 0px;
    border-color: #626166;
}

.visit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1140px) {
    .atelier-text-right {
        margin-left: 0;
    }
}

@media screen and (max-width: 991px) {
    .atelier-text-right {
        margin-left: 0;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        grid-column-start: span 4;
        -ms-grid-column-span: 4;
        grid-column-end: span 4;
        justify-self: auto;
        align-self: center;
        margin: 0;
        padding: 0;
    }
    .heading.atelier.div {
        text-align: center;
        font-size: 70px;
        line-height: 50px;
        margin-bottom: 10px;
        padding: 0
    }
    .heading.atelier.div br {
        display: none;
    }
    .heading.atelier.div.div2 {
        text-align: center;
    }
    .headline-atelier {
        line-height: 55px;
        font-size: 60px;
        text-align: center;
    }
    .text-address {
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 0 10px;
        text-align: center;
        line-height: 30px;
        font-weight: normal;
    }
    .heading.atelier {
        font-size: 50px !important;
        margin-top: 20px;
    }
    .gallery-grid-container.top {
        margin-top: -35px;
        display: block;
    }
    .text-atelier-1,
    .text-block-atelier {
        text-align: center;
        margin-top: 0;
    }
    .gallery-grid-container.secondary {
        grid-column-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .gallery-thumbnail.image.bottom {
        border: 0;
        height: 300px
    }
    .headline-block-atelier {
        margin: 70px 0;
    }
    .gallery-thumbnail.image,
    .gallery-thumbnail.img {
        border: 25px solid #e0d6cb;
    }
}