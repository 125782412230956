@font-face {
    font-family: "Franklin";
    src: local("Franklin"), url("./Franklin\ Gothic\ Condensed.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "Neuzeitgro lig";
    src: local("Neuzeitgro lig"), url("./NeuzeitGro-Lig.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Neuzeitgro lig";
    src: local("Neuzeitgro lig"), url("./NeuzeitGro-Bol.ttf") format("truetype");
    font-weight: bold;
}