.collection-list-wrapper {
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
}

.product-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    margin-right: -15px;
    margin-left: -15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    background-color: transparent;
    width: 100%;
}

.product-item img {
    position: relative;
    display: block;
    overflow: visible;
    height: 250px;
    margin-right: 0px;
    padding: 0px 15px;
    background-color: transparent;
    object-fit: cover;
    transition: opacity 400ms ease;
}

.product-item {
    cursor: pointer
}

.product-item:hover img {
    opacity: .8
}

.product-item:hover .product-price {
    opacity: 1
}

.product-name {
    position: relative;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family: Franklin, sans-serif;
}

.product-price {
    text-align: center;
    opacity: 0;
    transition: opacity 400ms ease;
}