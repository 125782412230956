.header {
    position: sticky;
    top: 0;
    background-color: #fbf9f7;
    z-index: 10;
}

.navigation {
    position: relative;
    top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 20px 30px 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0px solid #000;
    background-color: transparent;
    font-weight: normal;
}

.navigation-items {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-bottom: 60px;
    padding-top: 10px;
    text-align: center;
}

.navigation-item {
    display: block;
    padding: 0px 30px 0px 0px;
    font-family: 'Neuzeitgro lig', sans-serif;
    color: #2d2c30;
    font-size: 13px;
    line-height: 13px;
    font-weight: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.navigation-wrap {
    display: flex;
    width: 33%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -10px;
}

.navigation-wrap.right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.navigation-item.active {
    color: rgb(172, 170, 177)
}

.logo-container {
    margin-bottom: 5px;
}

.logo-label {
    text-transform: uppercase;
    font-family: 'Neuzeitgro lig', sans-serif;
    font-size: 8px;
    letter-spacing: 1.1px;
    margin-left: 1px;
    opacity: .7;
}

.dropdown {
    position: relative;
}

.dropdown .dropdown-header {
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Neuzeitgro lig', sans-serif;
    color: #2d2c30;
    font-size: 13px;
    font-weight: normal;
}

.dropdown .dropdown-list-container {
    position: absolute;
    background-color: #fff;
    top: 30px;
    left: -20px;
    font-family: 'Neuzeitgro lig', sans-serif;
    color: #2d2c30;
    font-size: 13px;
    line-height: 40px;
    font-weight: normal;
    letter-spacing: 2px;
    padding: 10px 10px;
    text-transform: uppercase;
    box-shadow: 0 0 8px rgba(0, 0, 0, .2);
    border-radius: 2px;
}

.dropdown .list-item {
    padding: 0 10px;
    border-radius: 2px;
    cursor: pointer;
    text-align: left;
}

.dropdown .list-item:hover {
    background-color: #fbf9f7
}

.menu {
    position: absolute;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 100px 20px 20px 20px;
    font-family: 'Franklin', sans-serif;
    z-index: 98;
}

.menu a {
    padding-bottom: 38px;
    font-size: 26px;
    font-family: 'Franklin', sans-serif;
}

.menu-icon.close {
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
}

.menu .navigation-item.active {
    color: rgb(214, 194, 172)
}

.menu .dropdown-header {
    font-size: 26px;
    font-family: 'Franklin', sans-serif;
    text-align: left;
}

.menu .dropdown-list-container {
    width: 100%;
    left: 0px;
    top: 40px;
}

@media screen and (min-width: 767px) {
    .large {
        display: block;
    }
    .small {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .large {
        display: none
    }
    .small {
        display: grid;
        grid-template-columns: 0.33fr 0.34fr 0.33fr;
        padding: 10px 0 0 0;
        margin-bottom: 10px;
    }
    .menu-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    .logo-div {
        text-align: center;
    }
    .logo-label {
        font-size: 8px;
    }
    .Dropdown-root.dropdown {
        width: fit-content;
    }
    .Dropdown-menu {
        width: calc(100vw - 40px) !important;
        left: 0 !important;
    }
    .navigation-item {
        line-height: 30px;
    }
    .navigation-items {
        margin-bottom: 0;
    }
}