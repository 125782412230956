html {
  max-width: 100vw;
}

body {
  font-family: 'Neuzeitgro lig', sans-serif;
  background-color: #fbf9f7;
  color: #2d2c30;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0px;
  margin: 0;
  font-synthesis: none;
  position: relative;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.center {
  text-align: center !important;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.container {
  width: 100%;
  height: auto;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
}

.centered-container {
  max-width: 1140px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.flex-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block {
  margin-top: 0px;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  white-space: pre-line;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
}

.image-div-right {
  position: relative;
  margin-left: -8%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  -o-object-fit: fill;
  object-fit: fill;
}

.image-div-left {
  margin-right: -8%;
  margin-left: 0%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}

.image-div-left.surmesure {
  margin-right: 0;
  flex: 0 0 50%;
}

.flex-div-text.surmesure {
  flex: 0 0 50%;
}

.intro-content-about {
  position: static;
  margin: 0 auto 80px auto;
  padding-bottom: 40px;
  color: #42403f;
  text-align: left;
}

.flex-div-text {
  position: relative;
  z-index: 2;
  overflow: visible;
  margin-left: 0px;
  padding: 40px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 55%;
  -ms-flex: 0 55%;
  flex: 0 55%;
  background-color: #fff;
  box-shadow: 0 0 11px -6px rgb(0 0 0 / 15%);
  -o-object-fit: fill;
  object-fit: fill;
}

.heading.heading-about {
  line-height: 55px;
  text-align: left;
}

.heading {
  margin-top: 0px;
  margin-bottom: 30px;
  direction: ltr;
  font-family: Franklin, sans-serif;
  color: #332d2c;
  font-size: 70px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  text-indent: 0px;
  text-transform: none;
}

.transparent {
  color: transparent;
}

.img-container {
  height: 400px;
  width: 100%;
}

.img-container.flexbox {
  display: flex;
  align-items: center;
}

.img-container.atelier {
  height: 600px;
  width: 100%;
}

.loading {
  filter: blur(5px);
}

@media screen and (max-width: 1140px) {
  .w-container {
    margin-left: 10%;
    margin-right: 10%;
    max-width: 80%;
  }
  .container {
    margin-right: 5%;
    margin-left: 5%;
    max-width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .gallery-grid-container {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  .gallery-thumbnail.img {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
  .flex-container {
    display: block;
  }
  .image-div-right,
  .image-div-left {
    margin: 0;
  }
  .heading {
    font-size: 60px;
  }
  .img-container,
  .img-container.atelier {
    height: auto;
  }
  .img-side {
    height: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .w-container {
    margin-left: 20px;
    margin-right: 20px;
    max-width: calc(100% - 40px);
  }
  .container {
    margin-right: 10px;
    margin-left: 10px;
    max-width: calc(100% - 20px);
  }
}

.section-home.product-section .product-wrap a img {
  overflow: hidden;
}
