.gallery {
    display: grid;
}

.gallery img {
    object-fit: cover;
    margin: 0 !important;
    height: 200px !important;
}

.ReactGridGallery_tile-viewport {
    height: 200px !important;
}

@media screen and (max-width: 991px) {
    .ReactGridGallery_tile-viewport,
    .ReactGridGallery_tile {
        width: 100% !important;
        height: auto !important;
    }
    .gallery {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .gallery img {
        width: 100% !important;
        height: auto !important;
    }
}