    .text-block-right-home {
        margin-top: 0px;
        font-family: 'Neuzeitgro lig', sans-serif;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        margin-top: -20px;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .gallery-grid-container.home {
        margin-top: -52px;
    }
    
    .heading.home {
        position: relative;
        margin-bottom: 20px;
        line-height: 75px;
    }
    
    .history {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: visible;
        height: auto;
        margin: 150px auto;
        padding: 150px 0px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: transparent;
        background-image: url("../../assets/img/a_Leather_bags_and_acessories.png");
        background-position: 50% 0%;
        background-size: cover;
        background-clip: border-box;
        -webkit-text-fill-color: inherit;
        -o-object-fit: fill;
        object-fit: fill;
    }
    
    .history-div-block {
        width: 50%;
        padding: 0px;
        background-color: transparent;
    }
    
    .heading.history-block {
        color: #fff;
        font-size: 50px;
        line-height: 45px;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-line;
    }
    
    .text-block.history-block {
        display: block;
        width: 60%;
        margin-right: auto;
        margin-bottom: 0px;
        margin-left: auto;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }
    
    .section-home.product-section {
        margin: 50px 0 100px 0;
    }
    
    .scroll-section {
        margin-top: -60px;
        padding: 60px 0;
        height: 100vh
    }
    
    .scroll-grid {
        display: grid;
        grid-template-columns: 0.2fr 0.2fr 0.2fr 0.15fr 0.25fr;
        justify-items: center;
        position: relative;
    }
    
    @media screen and (min-width: 991px) {
        #scroll-img4 {
            margin-top: 250px;
        }
        #scroll-img3 {
            margin-left: 25px;
        }
        #scroll-img2 {
            margin-top: 50px;
            margin-left: 50px;
        }
        #scroll-img6 {
            margin-top: 200px;
        }
    }
    
    @media screen and (max-width: 991px) {
        .text-block-right-home {
            grid-row-start: span 1;
            -ms-grid-row-span: 1;
            grid-row-end: span 1;
            -ms-grid-column: span 4;
            grid-column-start: span 4;
            -ms-grid-column-span: 4;
            grid-column-end: span 4;
            justify-self: auto;
            -ms-grid-row-align: center;
            align-self: center;
            margin: 0;
            padding: 0;
        }
        .collection-list-wrapper {
            position: relative;
            display: flex;
            width: auto;
            margin-right: 0px;
            margin-left: 0px;
            align-items: center;
            flex-wrap: wrap;
        }
        .product-wrap {
            display: block;
            width: 50%;
            height: auto;
            max-width: none;
            margin-right: 0px;
            margin-bottom: 60px;
            margin-left: 0px;
            padding: 0px;
            text-align: center;
        }
        .text-block.history-block {
            width: 80%;
        }
        .heading.home.care {
            font-size: 50px;
        }
        .scroll-section {
            height: auto;
        }
        .scroll-grid {
            grid-template-columns: 0.5fr 0.5fr;
        }
        #scroll-img4,
        #scroll-img6,
        #scroll-img3,
        #scroll-img2 {
            margin: 0;
        }
        .scroll-grid {
            align-items: center;
        }
        .product-price {
            opacity: 1;
        }
        #scroll-img2 {
            width: 60%
        }
        #scroll-img6 {
            width: 45%
        }
        #scroll-img3 {
            display: none;
        }
        #scroll-img1,
        #scroll-img6,
        #scroll-img4,
        #scroll-img2 {
            transform: none !important
        }
        .heading.history-block {
            font-size: 40px;
            line-height: 40px;
        }
    }
    
    @media screen and (max-width: 500px) {
        .product-wrap {
            width: 50%;
            margin-bottom: 20px;
        }
        .product-name {
            margin-top: 5px
        }
        .product-item img {
            height: auto;
            width: 100%;
            padding: 5px;
        }
        .history {
            padding: 60px 0
        }
        .history-div-block {
            width: 90%;
        }
    }